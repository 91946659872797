import { useState, useEffect } from "react";
import { graphql } from "gatsby";
import { useTranslation } from "gatsby-plugin-react-i18next";
import { updateCurrency } from "@services";
import { useGetCountry } from "@hooks";

import "@stylesComponents/BuyCourse.scss";

const time = {
  one: {
    price: 200,
    currency: "USD"
  },
  two: {
    price: 450,
    currency: "USD"
  },
  // three: {
  //   price: 600,
  //   currency: "USD"
  // },
  // four: {
  //   price: null,
  //   currency: "USD"
  // }
};

const BuyCourse = () => {
  const { t } = useTranslation("courses");
  const country = useGetCountry();
  const [months, setMonths] = useState(time["one"]);
  const [exchange, setExchange] = useState("");
  const [customAmount, setCustomAmount] = useState("");

  const handleMonth = (month) => {
    setMonths(time[month]);
    if (month === "four") setExchange("0");
  };

  const handleCustomAmount = (event) => {
    setCustomAmount(event.target.value);
  };

  useEffect(() => {
    (async () => {
      const service = await updateCurrency({
        price: months.price !== null ? months.price : customAmount,
        currency: "MXN"
      });
      setExchange(service.amount);
    })();
  }, [months, customAmount]);

  return (
    <section className="buy-course" id="courses">
      <div className="buy-course__container">
        <h2 className="buy-course__title">{t("buy-title")}</h2>
        <div className="buy-course__options">
          <button
            onClick={() => handleMonth("one")}
            data-active={months.price === 200}
            type="button"
            className="buy-course__option"
          >
            {t("buy-option-one")}
          </button>
          <button
            onClick={() => handleMonth("two")}
            data-active={months.price === 450}
            type="button"
            className="buy-course__option"
          >
            {t("buy-option-two")}
          </button>
          {/* <button
            onClick={() => handleMonth("three")}
            data-active={months.price === 600}
            type="button"
            className="buy-course__option"
          >
            {t("buy-option-three")}
          </button>
          <button
            onClick={() => handleMonth("four")}
            data-active={months.price === null}
            type="button"
            className="buy-course__option"
          >
            {t("buy-option-four")}
          </button> */}
        </div>
        {months.price !== null ? (
          <p className="buy-course__amount">
            {`$ ${months.price} ${months.currency}`}
            {country.code === "MX" ? <span>{`$ ${exchange} MXN`}</span> : ""}
          </p>
        ) : (
          <div className="buy-course__custom">
            <div className="buy-course__ipnut-container">
              <input
                onChange={handleCustomAmount}
                value={customAmount}
                type="number"
                name="customAmount"
                id="customAmount"
                className="buy-course__input"
              />
            </div>
            <p className="buy-course__amount">
              {country.code === "MX" ? <span>{`$ ${exchange} MXN`}</span> : ""}
            </p>
          </div>
        )}
        <a
          href={`https://clientzone.nftslearn.com/signin?amount=${months.price ?? customAmount}`}
          target="_blank"
          rel="noopener noreferrer"
          className="buy-course__button"
        >
          {t("buy-course")}
        </a>
      </div>
    </section>
  );
};

export default BuyCourse;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
