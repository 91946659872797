/* eslint-disable react/jsx-one-expression-per-line */
import { graphql } from "gatsby";
import { useTranslation } from "gatsby-plugin-react-i18next";

import Logo from "@images/logo.svg";
import AboutCourses from "@images/about-1.png";
// import AboutBoy from "@images/about-2.png";
import CourseImage from "@images/IMG.webp";
import LevelOne from "@images/Level1.webp";
import LevelTwo from "@images/Level2.webp";
import LevelThree from "@images/Level3.webp";
import Certificate from "@images/diploma.webp";
import YouOne from "@icons/you-1.svg";
import YouTwo from "@icons/you-2.svg";
import YouThree from "@icons/you-3.svg";
import ListIconOne from "@icons/icon-list1.svg";
import ListIconTwo from "@icons/icon-list2.svg";
// import ListIconThree from "@icons/icon-list3.svg";
import ListIconFour from "@icons/icon-list4.svg";

import "@stylesPages/Home.scss";

import SEO from "@components/SEO";
import Reviews from "@components/Reviews";
// import Courses from "@components/Courses";
import ContactForm from "@containers/ContactForm";
import Layout from "@components/Layout";
import BuyCourse from "@components/BuyCourse";

export default function Index({ location: { pathname } }) {
  const { t } = useTranslation("home");
  return (
    <Layout pathname={pathname}>
      <SEO title="Home" />
      <section className="hero">
        <div className="hero__container">
          <img src={Logo} alt="Logo" className="hero__logo" />
          <h1 className="hero__title">{t("hero-title")}</h1>
          <h1 className="hero__subtitle">{t("hero-subtitle")}</h1>
          <p className="hero__paragraph">{t("hero-paragraph")}</p>
          <a
            href="https://clientzone.nftslearn.com/signup"
            target="_blank"
            rel="noreferrer"
            className="hero__button"
          >
            {t("hero-button")}
          </a>
        </div>
      </section>
      <section className="about">
        <div className="about__container">
          <div className="about__grid">
            <div className="about__box">
              <h2 className="about__title">{t("learn-title")}</h2>
              <p className="about__paragraph">{t("learn-paragraph")}</p>
            </div>
            <div className="about__box about__box--image">
              <img src={AboutCourses} alt="Cursos" className="about__courses" />
            </div>
          </div>
        </div>
      </section>
      <section className="banner">
        <div className="banner__container">
          <h2 className="banner__title">
            {t("banner-titleOne")}
            <span className="banner__green">{t("banner-titleTwo")}</span>
          </h2>
          <p className="banner__paragraph">{t("banner-paragraph")}</p>
        </div>
      </section>
      {/* <Courses /> */}
      <section className="course">
        <div className="course__container">
          <div className="course__grid">
            <img src={CourseImage} alt="" className="course__img" />
            <article className="course__item">
              <h2 className="course__item-title" dangerouslySetInnerHTML={{ __html: t("course-title") }} />
              <p className="course__text">{t("course-text-one")}</p>
            </article>
            <article className="course__item">
              <p className="course__text">{t("course-text-two")}</p>
            </article>
            <article className="course__item course__item--list">
              <h2 className="course__item-title course__item-title--list">{t("course-title-two")}</h2>
              <ul className="course__list">
                <li className="course__list-item">
                  <img src={ListIconOne} alt="" className="course__list-item-icon" />
                  {t("course-list-one")}
                </li>
                <li className="course__list-item">
                  <img src={ListIconTwo} alt="" className="course__list-item-icon" />
                  {t("course-list-two")}
                </li>
                <li className="course__list-item">
                  <img src={ListIconFour} alt="" className="course__list-item-icon" />
                  {t("course-list-four")}
                </li>
              </ul>
            </article>
          </div>
          <div className="course__path">
            <h2 className="course__title" dangerouslySetInnerHTML={{ __html: t("course-title") }} />
            <div className="course__path-content">
              <article className="course__path-item">
                <img src={LevelOne} alt="" className="course__path-image" />
                <h3 className="course__path-title">{t("course-path-subtitle1")}</h3>
                <ul className="course__path-list">
                  <li className="course__path-list-item">{t("course-path-text1")}</li>
                  <li className="course__path-list-item">{t("course-path-text2")}</li>
                  <li className="course__path-list-item">{t("course-path-text3")}</li>
                  <li className="course__path-list-item">{t("course-path-text4")}</li>
                  <li className="course__path-list-item">{t("course-path-text5")}</li>
                  <li className="course__path-list-item">{t("course-path-text6")}</li>
                  <li className="course__path-list-item">{t("course-path-text7")}</li>
                </ul>
              </article>
              <article className="course__path-item">
                <img src={LevelTwo} alt="" className="course__path-image" />
                <h3 className="course__path-title">{t("course-path-subtitle2")}</h3>
                <ul className="course__path-list">
                  <li className="course__path-list-item">{t("course-path-text8")}</li>
                  <li className="course__path-list-item">{t("course-path-text9")}</li>
                  <li className="course__path-list-item">{t("course-path-text10")}</li>
                </ul>
              </article>
              <article className="course__path-item">
                <img src={LevelThree} alt="" className="course__path-image" />
                <h3 className="course__path-title">{t("course-path-subtitle3")}</h3>
                <ul className="course__path-list">
                  <li className="course__path-list-item">{t("course-path-text11")}</li>
                  <li className="course__path-list-item">{t("course-path-text12")}</li>
                  <li className="course__path-list-item">{t("course-path-text13")}</li>
                </ul>
              </article>
            </div>
            <article className="course__path-cert">
              <div className="course__path-cert-box">
                <h2 className="course__title">{t("course-path-title2")}</h2>
                <p className="course__text course__text--cert" dangerouslySetInnerHTML={{ __html: t("course-path-cert-text") }} />
              </div>
              <img src={Certificate} alt="" className="course__path-cert-image" />
            </article>
          </div>
        </div>
      </section>
      <BuyCourse />
      <section className="you">
        <div className="you__container">
          <h3 className="you__title">{t("you-title")}</h3>
          <div className="you__grid">
            <article className="you__item">
              <img src={YouOne} alt="You" className="you__image" />
              <p className="you__paragraph">{t("you-itemOne")}</p>
            </article>
            <article className="you__item">
              <img src={YouTwo} alt="You" className="you__image" />
              <p className="you__paragraph">{t("you-itemTwo")}</p>
            </article>
            <article className="you__item">
              <img src={YouThree} alt="You" className="you__image" />
              <p className="you__paragraph">{t("you-itemThree")}</p>
            </article>
          </div>
        </div>
      </section>
      <Reviews />
      <ContactForm />
    </Layout>
  );
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
